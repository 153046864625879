import { createSelector } from "reselect";

import {
    ACCOUNT_PROFILE_KYC_SHOW,
    BROKER_ONBOARDING_ACCOUNT,
    BROKER_DOCUMENTS_NAME,
    USER_ROLES,
    PHONE_TYPES,
    KYC_FULL_APPROVED,
    HIDE_ADEQUACY_TILE_STATUS_STEP,
    DOCUMENTS_TYPE,
    getTranslatedCountriesList,
} from "constants/index";

import {
    TILE_STATUS,
    CLUB_DEAL_STATUS,
    MANGOPAY_STATUS,
    MOBILE_STATUS,
    USER_REGISTRATION_ONBOARDING_STATUS,
    SECONDARY_MARKET_STATUS,
    PAYMENT_TYPE,
} from "constants/status";

import { checkEmailIsConfirmed } from "utils";

import { selectLocale, selectTenant } from "store/app/selectors";
import { extractUserMobilePhone } from "store/helpers";
const projectID = (state) => state.project.selectedProjectID;
const interestedInProjects = (state) => state?.user?.userData?.Subscription?.LinksProjects || [];
const getUser = (state) => state.user || {};
const getAuthToken = (state) => state.user.authToken;
const dashboardDetails = (state) => state.user.dashboardData || {};
const userDetails = (state) => (state.user && state.user.userData) || {};
const userPhone = (state) => (state.user && state.user.userData && state.user.userData.Phone) || [];
const userMobileDetails = (state) => state.user.additionalData.mobile;
const getAdditionalData = (state) => state.user.additionalData;
const userRoles = (state) =>
    state.user && state.user.userData && Array.isArray(state.user.userData.Roles) ? state.user.userData.Roles : [];
const tipsterOwnId = (state) =>
    state.user.additionalData &&
    state.user.additionalData.tile &&
    state.user.additionalData.tile.tipster &&
    state.user.additionalData.tile.tipster.UserTipsterOwnID;
const email = (state) => state.user && state.user.userData && state.user.userData.Email;
const country = (state) =>
    state.user && state.user.userData && state.user.userData.Address && state.user.userData.Address[0].Country;
const broker = (state) => (state.user && state.user.userData && state.user.userData.Broker) || {};
const brokerOnboardingProcess = (state) => state.user.additionalData.brokerOnboardingProcess;
const userMangopay = (state) => (state.user && state.user.userData && state.user.userData.Mangopay) || {};
const userTaxID = (state) => (state.user && state.user.userData && state.user.userData.TaxID) || "";
const getIsLoggedIn = (state) => state.user && state.user.isLoggedIn;
const userMinPayInAmount = (state) => (state.user && state.user.payInAmount) || {};
const getPayments = (state) => (state.user && state.user.payments) || [];
const bankAccount = (state) => (state.user && state.user.userData && state.user.userData.BankAccount) || [];

const portfolio = (state) => (state.user && state.user.userData && state.user.userData.Portfolio) || [];

const payInWalletBalance = (state) => (state.user && state.user.walletBalance && state.user.walletBalance.payInWallet) || {};
const blockedWalletBalance = (state) => (state.user && state.user.walletBalance && state.user.walletBalance.blockedWallet) || {};

const updateUserTaxDataTransitions = (state) => state?.user?.updateUserTaxDataTransitions || {};
const subscriptionsLoading = (state) => state.user.isSubscriptionsLoading || false;

export const selectInterestedInProjects = createSelector(interestedInProjects, (interestedInProjects) => interestedInProjects);

export const selectIsUserFetched = createSelector([getUser], (user) => user.isUserFetched);

export const selectUpdateUserTaxDataTransitions = createSelector(
    updateUserTaxDataTransitions,
    (updateUserTaxDataTransitions) => updateUserTaxDataTransitions
);

export const selectTaxData = createSelector(userDetails, (user) => {
    return {
        TaxID: user?.TaxID || "",
        FederalState: user?.FederalState || "",
        Religion: user?.Religion || "",
        Address: user?.Address || [],
    };
});

export const selectCanMakeCall = createSelector(getAdditionalData, (additionalData) => additionalData.canMakeCall);
export const selectUserPhone = createSelector(userPhone, (userPhone) => userPhone);

export const selectPrimaryMobilePhoneConfirmed = createSelector(userPhone, (userPhone) => {
    const primaryPhone = extractUserMobilePhone(userPhone).mobilePhone;
    return primaryPhone && primaryPhone.StatusConfirmed === "true";
});

export const selectMangopayActive = createSelector(
    [userDetails, userMangopay, userPhone],
    (userDetails, mangopayData, userPhone) => {
        const isKycCompleted = userDetails && userDetails.KYC && KYC_FULL_APPROVED.includes(userDetails.KYC.StatusKYC);
        const primaryPhone =
            Array.isArray(userPhone) &&
            userPhone.length > 0 &&
            userPhone.find((x) => x.TypePhone === PHONE_TYPES.TypePhoneMobile);
        const isMobileValidated = primaryPhone && primaryPhone.StatusConfirmed === "true";
        const isAdequacyCompleted = !!HIDE_ADEQUACY_TILE_STATUS_STEP.find(
            (status) => status === (userDetails && userDetails.StatusAdequacy)
        );
        const statusMangopayActive = userDetails && userDetails.StatusMangopay === MANGOPAY_STATUS.ACTIVE;
        const isUserIDMangopay = !!(mangopayData && mangopayData.UserIDMangopay && mangopayData.UserIDMangopay !== "");
        return isKycCompleted && isMobileValidated && isAdequacyCompleted && statusMangopayActive && isUserIDMangopay;
    }
);

export const selectMangopayBlocked = createSelector(
    [userDetails, userMangopay, userPhone],
    (userDetails, mangopayData, userPhone) => {
        // const statusSmBlocked = userDetails.StatusMangopay === SECONDARY_MARKET_STATUS.BLOCKED
        const statusMangopayBlocked = userDetails && userDetails.StatusMangopay === MANGOPAY_STATUS.BLOCKED;
        const isKycCompleted = userDetails && userDetails.KYC && KYC_FULL_APPROVED.includes(userDetails.KYC.StatusKYC);
        const primaryPhone =
            Array.isArray(userPhone) &&
            userPhone.length > 0 &&
            userPhone.find((x) => x.TypePhone === PHONE_TYPES.TypePhoneMobile);
        const isMobileValidated = primaryPhone && primaryPhone.StatusConfirmed === "true";
        const isAdequacyCompleted = !!HIDE_ADEQUACY_TILE_STATUS_STEP.find(
            (status) => status === (userDetails && userDetails.StatusAdequacy)
        );
        const isUserIDMangopay = !!(mangopayData && mangopayData.UserIDMangopay && mangopayData.UserIDMangopay !== "");
        return isKycCompleted && isMobileValidated && isAdequacyCompleted && statusMangopayBlocked && isUserIDMangopay;
    }
);

export const selectSecondaryMarketActive = createSelector(
    userDetails,
    (userDetails) => userDetails.StatusSecondaryMarket === SECONDARY_MARKET_STATUS.ACTIVE
);

export const selectStatusMangopay = createSelector(userDetails, (userDetails) => userDetails.StatusMangopay || "");

export const selectStatusSecondaryMarket = createSelector(userDetails, (userDetails) => userDetails.StatusSecondaryMarket || "");

export const selectFinexityTermsConditionsConfirmed = createSelector(
    userDetails,
    (userDetails) => userDetails.IsFinexityTermsConditionsConfirmed === "true"
);

export const selectXpectoCustomerCleared = createSelector(
    userDetails,
    (userDetails) => userDetails?.KYC?.XpectoCustomerCleared === "true"
);

export const selectBankAccount = createSelector(bankAccount, (bankAccount) => bankAccount);

export const selectUserSelectedBankAccount = createSelector([bankAccount, userMangopay], (bankAccount, userMangopay) => {
    const selectedUID = userMangopay && userMangopay.LinkUserSelectedBankAccountPayout;
    return (Array.isArray(bankAccount) && bankAccount.length && bankAccount.find((x) => x.uid === selectedUID)) || {};
});

export const selectPrimaryBankAccount = createSelector(bankAccount, (bankAccount) => {
    return (
        (Array.isArray(bankAccount) &&
            bankAccount.length &&
            bankAccount.find((x) => x.TypeBankAccount === "typeBankAccountUserPrimary")) ||
        {}
    );
});

export const selectSubscription = createSelector(userDetails, (user) => {
    if (!user || !user.Email) return {};
    console.log(user, "user");
    const roles = user.Roles,
        subscription = user.subscriptions || [];

    const showClubDealProjects =
        Array.isArray(roles) &&
        !!roles.find((role) => role === USER_ROLES.CLUBDEALS) &&
        user.StatusClubDeal === CLUB_DEAL_STATUS.COMPLETED;

    if (showClubDealProjects) return Object.assign({}, subscription);

    const filteredProjects = [];
    const subscribedProjects = subscription?.filter((item) => item.ProjectID !== "");
    if (subscribedProjects.length > 0) {
        for (let i = 0, projects = subscribedProjects, len = projects.length, j = 0; i < len; i++) {
            if (projects[i] && projects[i].Tag && projects[i].Tag.startsWith("CD") && !showClubDealProjects) continue;
            filteredProjects[j++] = projects[i];
        }
    }

    return {
        Subscriptions: subscription,
        LinksProjects: filteredProjects,
    };
});

export const selectStatusVipInvestor = createSelector(userDetails, (userDetails) => userDetails.StatusVIP || "");

export const selectUserTaxId = createSelector(userTaxID, (userTaxId) => userTaxId);

export const selectIsLoggedIn = createSelector(getIsLoggedIn, (isLoggedIn) => isLoggedIn);

export const selectIDnowRedirectURL = createSelector(getAdditionalData, (additionalData) => additionalData?.IDnowRedirectURL);

export const selectIsTipsterPdfDownloaded = createSelector(brokerOnboardingProcess, (data) => data.isTipsterPdfDownloaded);

export const selectIsUserKyc = createSelector(
    userDetails,
    (userData) => userData.StatusKYC === TILE_STATUS.SUCCESS || userData.StatusKYC === TILE_STATUS.HIDE
);

export const selectDashboardStatus = createSelector([(state, arr) => arr, dashboardDetails], (arr, dashboardDetails) => {
    const obj = {};
    for (let i = 0, len = arr.length; i < len; i++) {
        obj[arr[i]] = dashboardDetails[arr[i]];
    }
    return obj;
});

export const selectTypeMobilePhonelDetails = createSelector(userDetails, (user) => {
    return {
        userEmail: user.Email,
        StatusMobilePhone: user.StatusMobilePhone,
        StatusTwoFactor: user.StatusTwoFactor,
        ...extractUserMobilePhone(user?.Phone),
    };
});

export const selectDataFromUserDetails = createSelector([(state, arr) => arr, userDetails], (arr, user) => {
    if (!user || !user.Email) return "";
    for (let i = 0, len = arr.length; i < len; i++) {
        user = user[arr[i]];
    }
    return user;
});

export const selectBrokerDocuments = createSelector(
    [(state, documentNames) => documentNames, broker],
    (documentNames, broker) => {
        const identification = BROKER_DOCUMENTS_NAME.identification,
            cooperationAgreements = BROKER_DOCUMENTS_NAME.cooperationAgreements,
            businessLicenses = BROKER_DOCUMENTS_NAME.businessLicenses,
            commercialExtract = BROKER_DOCUMENTS_NAME.commercialExtract;

        const obj = {
            [identification]: broker[identification] || [],
            [cooperationAgreements]: broker[cooperationAgreements] || [],
            [businessLicenses]: broker[businessLicenses] || [],
            [commercialExtract]: broker[commercialExtract] || {},
        };

        if (typeof documentNames === "undefined") {
            return obj;
        } else {
            const ret = {};
            documentNames.map((documentName) => {
                ret[documentName] = obj[documentName];
            });
            return ret;
        }
    }
);

export const selectBrokerOnboardingData = createSelector([broker, selectLocale], (b = {}, locale) => {
    const countriesList = getTranslatedCountriesList(locale);
    const c = b?.LinkCompany || {},
        ba = b?.LinkBankAccount || {},
        a = b?.LinkAddress || {};
    const country = a && a.Country && countriesList?.find((item) => a.Country === item.value);

    return {
        completeData: {
            name: c.CompanyName,
            zipcode: a.Zipcode,
            streetnumber: a.Streetnumber,
            street: a.Street,
            country: country,
            city: a.City,
            IBAN: ba.IBAN,
            BIC: ba.BIC,
            owner: ba.Owner,
            taxID: b.TaxID,
        },
        status: b.StatusBrokerOnboarding,
    };
});

export const selectEmail = createSelector(email, (email) => email);

export const selectCountry = createSelector(country, (country) => country);

export const selectTipsterOwnId = createSelector(tipsterOwnId, (tipsterOwnId) => tipsterOwnId);

export const selectFilterUserRoles = createSelector([userRoles, userDetails], (roles, user) => {
    const userRoles = {
        vip: false,
        admin: false,
        broker: false,
        club: false,
        brokerOnboarding: false,
        clubDealOnboarding: false,
    };

    if (roles && roles.length) {
        for (let i = 0, len = roles.length; i < len; i++) {
            userRoles[roles[i]] = true;
        }
    }

    if (
        (user.Broker && user.Broker.StatusBrokerAccount === BROKER_ONBOARDING_ACCOUNT.PENDING) ||
        (!user.Broker && userRoles.broker)
    ) {
        userRoles.broker = false;
        userRoles.brokerOnboarding = true;
    }

    if (userRoles.club && user.StatusClubDeal !== CLUB_DEAL_STATUS.COMPLETED) {
        userRoles.club = false;
        userRoles.clubDealOnboarding = true;
    }

    return userRoles;
});

export const selectIsClubDealCompleted = createSelector(
    userDetails,
    (userDetails) => userDetails.StatusClubDeal === CLUB_DEAL_STATUS.COMPLETED
);

export const selectIsUserStatusBrokerAccountActive = createSelector(
    broker,
    (broker) => broker.StatusBrokerAccount === BROKER_ONBOARDING_ACCOUNT.ACTIVE
);

export const selectIsUserTipsterConfirmed = createSelector(
    userDetails,
    (userDetails) => userDetails.StatusReferral === TILE_STATUS.ACTIVE
);

export const selectUserAdditionalData = createSelector(getAdditionalData, (data) => Object.assign({}, data));

export const selectAuthToken = createSelector(getAuthToken, (token) => token);

export const selectUserDashboardDetails = createSelector(dashboardDetails, (dashboardData) => dashboardData);

export const selectUserKycStatus = createSelector(userDetails, (userData) => userData.StatusKYC);

export const selectUserDetails = createSelector(userDetails, (userData) => userData);

export const selectUserRoles = createSelector(userDetails, (userData) => userData.Roles);

export const selectIsUserAdmin = createSelector(
    selectUserRoles,
    (userRoles) => !!userRoles?.find((role) => role === USER_ROLES.ADMIN)
);

export const selectIsUserTenantAdmin = createSelector(
    selectUserRoles,
    (userRoles) => !!userRoles?.find((role) => role === USER_ROLES.TENANT)
);

export const selectUserMobileDetails = createSelector(userMobileDetails, (userMobileData) => userMobileData);

export const selectKycShow = createSelector(dashboardDetails, ({ StatusKYC }) =>
    Boolean(ACCOUNT_PROFILE_KYC_SHOW.find((status) => status === StatusKYC))
);

export const selectKycCompleted = createSelector(
    dashboardDetails,
    ({ StatusKYC }) =>
        StatusKYC === TILE_STATUS.HIDE ||
        StatusKYC === TILE_STATUS.SUCCESS ||
        StatusKYC === TILE_STATUS.KYC_COMPLETED_IDNOW ||
        StatusKYC === TILE_STATUS.KYC_COMPLETED_POSTIDENT
);

export const selectKycFullCompleted = createSelector(userDetails, (userDetails) =>
    KYC_FULL_APPROVED.includes(userDetails?.KYC?.StatusKYC)
);

export const selectIsMobileNumberVerified = createSelector(
    userDetails,
    (user) => user.StatusTwoFactor === MOBILE_STATUS.SUCCESSFULLY_COMPLETED
);

export const selectUserPayments = createSelector(getPayments, (payments) => payments);

export const selectUserPaymentsFilter = createSelector(getPayments, (payments) => {
    const newArr = [];
    payments &&
        payments.forEach((payment) => {
            if (
                payment.TypePayment === PAYMENT_TYPE.USER_INVEST_EWALLET_TRANSFER ||
                payment.TypePayment === PAYMENT_TYPE.USER_PAYIN ||
                payment.TypePayment === PAYMENT_TYPE.USER_PAYOUT ||
                payment.TypePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_BUY ||
                payment.TypePayment === PAYMENT_TYPE.USER_SECONDARY_MARKET_SALE ||
                payment.TypePayment === PAYMENT_TYPE.USER_DIVIDEND_PAYOUT ||
                payment.TypePayment === PAYMENT_TYPE.USER_EXIT_PAYOUT
            ) {
                newArr.push(payment);
            }
        });
    return newArr;
});

export const selectUserId = createSelector(userDetails, (user) => user.uid);

export const selectMangoPayKycLevel = createSelector(userDetails, (user) => user.Mangopay && user.Mangopay.KYCLevel);

export const selectIsBlockedInFlowsMangoPay = createSelector(
    userDetails,
    (user) => user.Mangopay && user.Mangopay.IsBlockedInFlowsMangopay && user.Mangopay.IsBlockedInFlowsMangopay === "true"
);

export const selectIsBlockedOutFlowsMangoPay = createSelector(
    userDetails,
    (user) => user.Mangopay && user.Mangopay.IsBlockedOutFlowsMangopay && user.Mangopay.IsBlockedOutFlowsMangopay === "true"
);

export const selectIsUserAuthenticated = createSelector(
    [getUser, selectIsUserFetched],
    (user, isFetched) => !!(user.isLoggedIn && user.authToken && isFetched)
);

export const selectPayInWalletBalance = createSelector(payInWalletBalance, (walletData) => walletData.Amount || 0);

export const selectBlockedWalletBalance = createSelector(blockedWalletBalance, (walletData) => walletData.Amount || 0);

export const selectUserMangopay = createSelector(userMangopay, (mangopayData) => mangopayData);

export const selectUserMinPayInAmount = createSelector(userMinPayInAmount, (minPayInAmount) => minPayInAmount);

export const selectIsLoadingTiles = createSelector(getUser, (user) => user.isLoadingTiles);

export const selectIsUserLoading = createSelector(getUser, (user) => user.isUserLoading);

export const selectIsUserOnboardingCompleted = createSelector(
    userDetails,
    ({ UserRegistrationOnboardingStatus }) =>
        UserRegistrationOnboardingStatus === USER_REGISTRATION_ONBOARDING_STATUS.SUCCESS || false
);

export const selectUserPortfolio = createSelector(portfolio, (portfolio) => portfolio);

export const selectUserLeadSource = createSelector(userDetails, (userDetails) => userDetails.LeadSource || "");

export const selectUserStatusEmail = createSelector(userDetails, (userDetails) => userDetails.StatusEmail || "");

export const selectIsUserStatusEmailVerified = createSelector(selectUserStatusEmail, (StatusEmail) =>
    checkEmailIsConfirmed(StatusEmail)
);

export const selectIsUserTermsAndConditionsExpired = createSelector(getUser, (user = {}) => {
    const isFinexityTermsConditionsExpired = user?.termsAndConditions?.IsFinexityTermsConditionsExpired === true;
    const isEffectaCustomerTermsConditionsExpired = user?.termsAndConditions?.IsEffectaCustomerTermsConditionsExpired === true;
    const isTenantMarketplaceTermsConditionsExpired =
        user?.termsAndConditions?.IsTenantMarketplaceTermsConditionsExpired === true;

    return (
        isFinexityTermsConditionsExpired || isEffectaCustomerTermsConditionsExpired || isTenantMarketplaceTermsConditionsExpired
    );
});

export const selectUserTermsAndConditionsAcceptance = createSelector(
    [userDetails, getUser, selectTenant],
    (userDetails = {}, user = {}, tenant) => {
        const isFinexityTermsConditionsConfirmed = !!(
            userDetails?.Documents?.find((document) => document.TypeDocument === DOCUMENTS_TYPE.FINEXITY_TC) &&
            user?.termsAndConditions?.IsFinexityTermsConditionsExpired === false
        );
        const isEffectaCustomerInformationConfirmed = !!(
            userDetails?.Documents?.find((document) => document.TypeDocument === DOCUMENTS_TYPE.EFFECTA_TC) &&
            user?.termsAndConditions?.IsEffectaCustomerTermsConditionsExpired === false
        );
        const isTenantMarketplaceTermsConditionConfirmed = !!(
            userDetails?.Documents?.find(
                (document) => document.TypeDocument === DOCUMENTS_TYPE.MARKETPLACE_TC && document.Tenant === tenant
            ) && user?.termsAndConditions?.IsTenantMarketplaceTermsConditionsExpired === false
        );

        return {
            isTenantMarketplaceTermsConditionConfirmed,
            isFinexityTermsConditionsConfirmed,
            isEffectaCustomerInformationConfirmed,
        };
    }
);

export const selectUserTermsAndConditionsAccepted = createSelector(
    [selectUserTermsAndConditionsAcceptance, selectUserRoles],
    (termsAndConditionData) => {
        return !!(
            termsAndConditionData.isFinexityTermsConditionsConfirmed &&
            termsAndConditionData.isEffectaCustomerInformationConfirmed &&
            termsAndConditionData.isTenantMarketplaceTermsConditionConfirmed
        );
    }
);

export const selectUserIssuerLinkedProducts = createSelector(userDetails, (userData) => userData?.Issuer?.LinkProducts || []);

export const selectUserIssuerData = createSelector(userDetails, (userData) => [userData?.Issuer].filter(Boolean));

export const selectIsUserAllowedToShowProductDetail = createSelector(selectUserRoles, (userRoles) => {
    return !!(userRoles?.find((role) => role === USER_ROLES.ADMIN) || userRoles?.find((role) => role === USER_ROLES.ISSUER));
});

export const selectIsUserFullyOnBoarded = createSelector(
    [selectIsUserOnboardingCompleted, selectUserTermsAndConditionsAccepted, selectIsUserStatusEmailVerified],
    (isUserOnboardingCompleted, isUserTermsAndConditionsAccepted, isUserStatusEmailVerified) => {
        return isUserOnboardingCompleted && isUserTermsAndConditionsAccepted && isUserStatusEmailVerified;
    }
);

export const selectAvailableAssetForSelectedProject = createSelector([projectID, selectUserPortfolio], (projectID, portfolio) => {
    return portfolio.find((e) => e.LinkProject == projectID);
});

export const selectSubscriptionLoading = createSelector(subscriptionsLoading, (loading) => loading);

export const selectIsTanganyCreateWalletConfirmed = createSelector(userDetails, (user) => user.IsTanganyCreateWalletConfirmed);
